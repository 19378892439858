import moment from "moment-timezone";
import numeral from "numeral";
import {storageRef,fileURL} from "../store/config";
export const constants = {
  dayFormat:'YYYYMMDD',
  dateFormat:'YYYYMMDD HH:mm'
};
export const chunk = (size, arr) => {
  return arr.reduce((res, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!res[chunkIndex]) {
      res[chunkIndex] = [];
    }

    res[chunkIndex].push(item);

    return res;
  }, []);
};
export const toCamelCase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};
export const assignDateFilters = (obj, mDay) => {
  let uDay = Math.ceil(mDay.unix() / 60 / 60 / 24);
  let uWeek = Math.ceil(uDay / 7);
  let mKey = mDay.format("MMYYYY");

  Object.assign(obj["filterDays"], { [uDay]: true });
  Object.assign(obj["filterWeeks"], { [uWeek]: true });
  Object.assign(obj["filterMonths"], { [mKey]: true });

  return obj;
};
export const dateStringFix = (dateString) => {
  return dateString.replace(/ /g, "T");
};
export const convertDateTZ = (preTZ, timezone) => {
  let afterTZ = preTZ.clone();
  afterTZ.tz(timezone);
  return afterTZ;
};
export const reservationStatus = (item) => {
  let status = "";
  if (!item.status) return "";
  if (
    (item.status.approved || item.status.confirmed) &&
    moment().isAfter(moment.unix(item.eventDay)) &&
    (item.payment || item.thresholdNotMet)
  ) {
    status = "Completed";
  } else if (
    (item.status.confirmed || item.status.approved) &&
    moment().isAfter(moment.unix(item.eventDay)) &&
    item.pendingCheck
  ) {
    status = "Pending Check";
  } else if (
    (item.status.confirmed || item.status.approved) &&
    moment().isAfter(moment.unix(item.eventDay)) &&
    !item.donation
  ) {
    status = "Pending Results";
  } else if (
    (item.status.confirmed || item.status.approved) &&
    moment().isAfter(moment.unix(item.eventDay)) &&
    !item.payment
  ) {
    status = "Pending Payment";
  } else if (item.status.pending && item.invite) {
    status = "Pending Invite";
  } else if (item.status.missingInfo) {
    status = "Missing Info";
  } else if (item.status.pending) {
    status = "Pending Approval";
  } else if (item.status.denied) {
    status = "Denied";
  } else if (item.status.cancelled) {
    status = "Cancelled";
  } else if (item.status.confirmed) {
    status = "Confirmed";
  }else if(item.status.approved&&item.event?.multiDay&&moment().isAfter(moment.unix(item.startDay||moment().unix()))){
    status = "In Progress";   
  } else if (item.status.approved) {
    status = "Approved";
  } else if (item.status.lapsed) {
    status = "Lapsed";
  }
  return status;
};
export const donationStatus = (item) => {
  let status = "";
  if (!item.status) return "";
  if (
    (item.status.approved) &&
    item.fulfillDate
  ) {
    status = "Fulfilled";
  } else if (item.status.pending) {
    status = "Pending Approval";
  } else if (item.status.denied) {
    status = "Denied";
  } else if (item.status.cancelled) {
    status = "Cancelled";
  } else if (item.status.approved) {
    status = "Approved";
  } else if (item.status.lapsed) {
    status = "Lapsed";
  }
  return status;
};
export const specificEventDates = (e) => {
  let starts = moment(e?.duration?.starts,constants.dayFormat);
  const ends = moment(e?.duration?.ends,constants.dayFormat);
  if(moment().isAfter(ends)) {
     return [];
  } else {
      let eventDates = [];
      let totalNumDays = ends.diff(starts, "days");
      for (let dayNum = 1; dayNum <= totalNumDays; dayNum = dayNum + 1) {
          let startDate = starts.clone();
          let newDate = moment(startDate.add(dayNum, "days"));
          let dayOfWeek = newDate.format("dddd");
          if ((dayOfWeek in e.repeatDays)&&!moment().isAfter(newDate)) {
            eventDates.push({
              day: newDate.format(constants.dayFormat),
              shortDate: newDate.format("ddd"),
              longDate: newDate.format("MMM D"),
              dayOfWeek: newDate.format("dddd")
            });
          }
      }
      return eventDates;
  }
}
export const perpetualEventDates = (e) => {
  let eventDates = [];
  let startTime = moment(e.starts).format("HH:mm");
  let starts = moment().format(constants.dayFormat) + " " + startTime;
  let totalNumDays = 365;
  for (let dayNum = 1; dayNum <= totalNumDays; dayNum = dayNum + 1) {
    let startDate = moment(starts,'YYYYMMDD HH:mm');
    let newDate = moment(startDate.add(dayNum, "days"));
    let dayOfWeek = newDate.format("dddd");
    if (dayOfWeek in e.repeatDays) {
      eventDates.push({
        day: newDate.format(constants.dayFormat),
        shortDate: newDate.format("ddd"),
        longDate: newDate.format("MMM D"),
        dayOfWeek: newDate.format("dddd")
      });
    }
  }
  return eventDates;
}
export const formatEventField = (e, field, value) => {
  switch (field) {
    case "created":
    case "updated":
    case "approved_date":
      value = value?moment(value).format("ll"):'';
      break;
    case "eventDay":
      value = moment.unix(value).format("ll");
      break;
    case "sales":
    case "instore_sales":
    case "online_sales":
    case "donation":
      value = numeral(value).format("$0,0.00");
      break;
    case "payment":
      value = value ? "Yes" : "No";
      break;
    case "status":
      value = reservationStatus(e);
      break;
  }
  return value;
};
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const string_to_slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};
export const formatCurrency = (value) => {
  return numeral(value).format("0,0.00");
};
export const doInitials = (name) => {
  return (name?name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g).join(''):'');
};
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
export const download = async (file) => {
  let ref = storageRef(file.fullPath);
  let url = await fileURL(ref);
  let response = await fetch(url);
  let buffer = await response.arrayBuffer();
  url = window.URL.createObjectURL(new Blob([buffer],{type:file.contentType}));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', ref.name);
  document.body.appendChild(link);
  link.click();
};